import React from "react";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import Link from "@amzn/awsui-components-react/polaris/link";
import Button from "@amzn/awsui-components-react/polaris/button";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import { useTranslation } from "react-i18next";
import { useFeatures, featureIsEnabled } from "../hooks/features";

import { useSearchParams } from "react-router-dom";
import { AmazonLogo } from '../images/amazonLogo'
import externalLinks from "../data/externalLinks.json";

function SupportLink({ type }: { type: "aciWiki" | "vcWiki" | "feature" | "feedback" }) {
  const { t } = useTranslation();

  return (
    <Link
      href={externalLinks.support[type]}
      target="_blank"
      rel="noreferrer"
      data-external-analytics-on="click"
      data-external-analytics-name={"footer_" + type}
      data-aci-analytics-name={"footer_" + type}>{t(`footer.${type}`)}</Link>
  );
}

export function BottomNav() {
  const { t } = useTranslation();
  const features = useFeatures();
  const [searchParams] = useSearchParams();

  if(!featureIsEnabled("BenevityButBetter", features, searchParams)) return null;

  return (
    <footer>
      <Grid gridDefinition={[
          { colspan: { default: 12, xl: 2, l: 2,  m: 2, s: 4, xs: 4, xxs: 6 } },
          { colspan: { default: 12, xl: 5, l: 5,  m: 5, s: 4, xs: 4, xxs: 6 } },
          { colspan: { default: 12, xl: 5, l: 5, m: 5, s: 4, xs: 4, xxs: 6 } }]}>
        <AmazonLogo/>
        <div className="links">
          <SpaceBetween direction="vertical" size="s">
            <SupportLink type="aciWiki"/>
            <SupportLink type="vcWiki"/>
            <SupportLink type="feature"/>
            <SupportLink type="feedback"/>
          </SpaceBetween>
        </div>
        <SpaceBetween size="l">
          <h4>{t("footer.follow.title")}</h4>
          <span className="copy">{t("footer.follow.copy")}</span>
          <Button
            iconName="external"      
            href={externalLinks.support.a2z}     
            iconAlign="right"
            target="_blank"
            fullWidth
            data-external-analytics-on="click"
            data-external-analytics-name={"footer_a2zLink"}
            data-aci-analytics-name={"footer_a2zLink"}
            variant="primary">{t("footer.follow.button")}</Button>
        </SpaceBetween>
      </Grid>
    </footer>
  );
}