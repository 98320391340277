import { ReactElement, ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { featureIsEnabled, useFeatures } from "../hooks/features";
import { useUser } from "../hooks/user";
import { getClient } from "../utils/getClient";
import { useSearchParams } from "react-router-dom";
import { useUserInfoOrRedirectToAuth } from "../utils/auth";

/**
 * Mitigation for https://issues.amazon.com/issues/cce-3592
 * Currently we only try to load the user id when they request shifts, but
 * by default the page requests 25 shifts all at once.
 * 
 * If this happens we overload the redshift query. 
 * 
 * To mitigate we are going to try to load the shift once when the page loads.
 */
export const shiftLoadedContext = createContext<boolean>(false);

/**
 * waits for features to be loaded (returning false until they are)
 * then if the features are loaded, but BBB is no enabled we just return true.
 * but if BBB is enabled then we try to load an arbitrary signup to make sure 
 * the user is cached in our system.
 */
export function ProvideSignupLoadContext({ children }: { children: ReactNode }) {
  const features = useFeatures();
  const [searchParams] = useSearchParams();
  const user = useUser();

  const [loaded, setLoaded] = useState<boolean>(false);
  const bbbEnabled: (boolean | undefined) = useMemo(() => {
    if (!features) {
      return undefined;
    }
    return featureIsEnabled("BenevityButBetter", features, searchParams);
  }, [features, searchParams]);

  const loadAnySignup = useCallback(async () => {
    if (bbbEnabled === undefined) {
      return;
    }
    if (!bbbEnabled) {
      return setLoaded(true);
    }
    if (!user.userId && user.isAuthenticated) {
      return;
    }

    try {
      await getClient().getSignupsByQueryParam({ eventId: "1234", alias: user.userId });
    } catch (e) {
      //Swallow it. This is just to work around a ton of user cache requests
    } finally {
      setLoaded(true);
    }
  }, [bbbEnabled, user]);

  useEffect(() => {
    loadAnySignup();
  }, [loadAnySignup]);
  return <shiftLoadedContext.Provider value={loaded}>{children}</shiftLoadedContext.Provider>

}

export const GateOnSignupLoaded = ({ loading, render }: { loading: () => ReactElement, render: () => ReactElement }) => {
  const loaded = useContext(shiftLoadedContext);
  if (loaded) {
    return render();
  }
  return loading();
}

export interface FeatureState { feature: string, value: boolean }

export type FeatureContext =
  { loading: true } |
  { loading: false, features: FeatureState[] }

export const featureContext = createContext<FeatureContext>({ loading: true });


/**
 * Use the feature operation to populate featureContext for all child components
 * 
 * If it is not production, it requires the user to be authenticated.
 */
export function ProvideFeatureContext({ children }: { children: ReactNode }) {
  const user = useUser();
  const [state, setState] = useState<FeatureState[] | undefined>(undefined);
  const loadFeatures = useCallback(async () => {
    //in prod we must be authenticated before getting features.
    if (!user.isAuthenticated) {
      return;
    }
    const features: FeatureState[] = (await getClient().getFeatures({}, {})).data || [];
    setState(features);
  }, [user.isAuthenticated]);

  useEffect(() => {
    loadFeatures();
  }, [loadFeatures]);

  return <featureContext.Provider value={state ? { loading: false, features: state } : { loading: true }} >
    {children}
  </featureContext.Provider>;
}

/**
 * render the element returned by the loading parameter until features are available, at which point render 
 * the content of the page. 
 */
export function GateOnFeatureLoad({ loading, render }: { loading: () => ReactElement, render: () => ReactElement }) {
  const ConditionalRender = () => {
    const features = useFeatures();
    if (features !== null) {
      return render();
    }
    return loading();
  };
  return <ConditionalRender />
}

export interface UserInfo {
  userId: string;
  employeeId: string;
  permissions: string[];
}

export type UserContext = { user: UserInfo, error?: string };

export const userContext = createContext<UserContext>({ user: { userId: "", employeeId: "", permissions: [] } });

/**
 * Populate the userContext with the user currently authenticated by
 * Amplify Auth.
 * 
 * If the user is not authenticated, redirect to cognito authentication.
 * 
 * If this is not the production envirionment, do nothing.
 */
export function ProvideAuthenticatedUserContext({ children }: { children: ReactNode }) {
  const { userAlias, employeeId, permissions, error } = useUserInfoOrRedirectToAuth();
  const userData = userAlias === undefined ? { userId: "", employeeId: "", permissions: [] } : { userId: userAlias, employeeId, permissions };
  return <userContext.Provider value={{ user: userData, error: error }}> {children} </userContext.Provider>
}

/**
 * render only the loading element until the user is authenticated and then render the render element.
 * 
 * If user does not have permission to view the site, render the Access Denied page
 */
export function GateOnUserLoad({ loading, error, unauthorized, authorized }: { loading: () => ReactElement, error: () => ReactElement, unauthorized: () => ReactElement, authorized: () => ReactElement }) {
  const ConditionalRender = () => {
    const user = useUser();

    if (user.isAuthenticated) {
      const permissions = user.permissions ? user.permissions : [];
      if (permissions.includes("canView")) {
        return authorized();
      }
      return unauthorized();
    }
    if (user.error) {
      return error();
    }
    return loading();
  };
  return <ConditionalRender />
}
