/* istanbul ignore file */
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './i18n';
import { Amplify } from 'aws-amplify';
import { getAuthConfig } from './utils/requestConfig';
import { ProvideAuthenticatedUserContext, ProvideFeatureContext, ProvideSignupLoadContext } from './components/context';
import { AppRoutes } from './routes/appRoutes';

const callbackUrl = window.location.origin;
const authConfig = getAuthConfig()
const amplifyConfig = {
  Auth: {
    identityPoolId: authConfig.identityPoolId,
    region: authConfig.region,
    userPoolId: authConfig.userPoolId,
    userPoolWebClientId: authConfig.userPoolClientId,
    mandatorySignIn: false,
    signUpVerificationMethod: 'code',
    oauth: {
      domain: authConfig.domain,
      scope: [
        'email',
        'profile',
        'openid',
        'aws.cognito.signin.user.admin'
      ],
      redirectSignIn: callbackUrl,
      redirectSignOut: callbackUrl,
      responseType: 'code'
    }
  }
};
Amplify.configure(amplifyConfig)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

export function AppRoutesWithContext() {
  return <ProvideAuthenticatedUserContext>
    <ProvideFeatureContext>
      <ProvideSignupLoadContext>
        <AppRoutes />
      </ProvideSignupLoadContext>
    </ProvideFeatureContext>
  </ProvideAuthenticatedUserContext>
}

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppRoutesWithContext />
    </BrowserRouter>
  </React.StrictMode>
);
