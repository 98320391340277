import { useTranslation } from "react-i18next";
import { Shift, Signup, Event, QuestionAnswer, Question, Option } from "@amzn/red-velvet-api";
import { getShiftLengthLabel } from "../eventDisplay";
import Button from "@amzn/awsui-components-react/polaris/button";
import Cards from "@amzn/awsui-components-react/polaris/cards";
import Header from "@amzn/awsui-components-react/polaris/header";
import Link from "@amzn/awsui-components-react/polaris/link";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import { useCallback, useState, useEffect } from "react";
import { useUser } from "../../hooks/user";
import { asDateString, asTimeString } from "../../utils/dateFormatting";
import externalLinks from "../../data/externalLinks.json";
import { featureIsEnabled, useFeatures } from "../../hooks/features";
import { COMPLEX_SIGNUP_FEATURE_FLAG } from "../../utils/constants";
import { ComplexSignupDialog } from "../complexSignup/signupDialog";
import { useSearchParams } from "react-router-dom";
import { useTshirtSizeComplexSignupFlag } from "../../utils/configFlag";
import { ComplexSignupInputs } from "../../hooks/betterEvents";
import { notNull } from  "../../utils/tsUtils"

export interface ModalData {
  loading: boolean,
  waiverLinkOpened: boolean,
  formValidated: boolean,
  questionAnswers?: {
    question: Question,
    options: Option[];
    answer?: Option
  }[]
}

interface ShiftCardsProps {
  event: Event;
  timezone?: string;
  signupInBenevityOnly: boolean;
  shifts: readonly Shift[];
  shiftsPerRow: number;
  signups: Signup[];
  selectedTimezone: string;
  doSignup: (
    shift: Shift,
    alias: string,
    complexSignups?: ComplexSignupInputs
  ) => Promise<void>;
  doCancel: (signup: Signup) => Promise<void>;
}

interface ActionLinkProps {
  signupInBenevityOnly: boolean;
  event: Event;
  shift: Shift;
  modalData: ModalData;
  setModalData: React.Dispatch<React.SetStateAction<ModalData>>;
  modalVisible: boolean;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  signup?: Signup;
  doSignup: (
    shift: Shift,
    alias: string,
    complexSignups?: ComplexSignupInputs
  ) => Promise<void>;
  doCancel: (signup: Signup) => Promise<void>;
}

export function ActionLink(props: ActionLinkProps) {

  const { signupInBenevityOnly, event, shift, modalData, setModalData, modalVisible, setModalVisible, signup, doSignup, doCancel } = props

  const [loading, setLoading] = useState(false);
  const [complexSignupData, setComplexSignupData] = useState<ComplexSignupInputs>({...{}});
  const user = useUser();
  const features = useFeatures()
  const [searchParams] = useSearchParams();

  const registerSignup = useCallback(async () => {
    setLoading(true);
    await doSignup(shift, user.userId);
    setLoading(false);
  }, [doSignup, shift, user.userId]);

  const cancelSignup = useCallback(async () => {
    if(!signup) {
      return;
    }
    setLoading(true);
    await doCancel(signup);
    setLoading(false);
  }, [setLoading, doCancel, signup])

  const registerComplexSignup = useCallback(async () => {
    const convertedQuestionAnswers: QuestionAnswer[] = 
      modalData.questionAnswers?.map((set) => {
        if (set.question && set.answer)
          return {
            questionId: set.question.id,
            optionId: set.answer.id
          };
        return null;
      }).filter(notNull) ?? [];
    const complexSignupInput: ComplexSignupInputs = {
      numberOfGuests: complexSignupData.numberOfGuests,
      tShirtSize: complexSignupData.tShirtSize,
      questionAnswer: convertedQuestionAnswers,
      waiverAccepted: complexSignupData.waiverAccepted
    }
    setModalData((prev) => ({
      ...prev,
      loading: true,
    }));
    await doSignup(shift, user.userId, complexSignupInput);
    setModalData((prev) => ({
      ...prev,
      loading: false,
    }));
    setModalVisible(false);
  }, [doSignup, setModalData, setModalVisible, shift, user.userId, complexSignupData, modalData]);

  const userSignedUpToShift = signup !== undefined
  const isComplexShiftSignup = 
    event.allowGuests 
    || (event.questions && event.questions.length > 0)
    || event.waiver?.acceptance !== "none"
    || (useTshirtSizeComplexSignupFlag ? event.hasTShirt !== undefined : true)

  if (userSignedUpToShift) {
    return shift.complete ? CompletedShift(loading, shift, cancelSignup) : RegisteredShift(loading, shift, cancelSignup)
  }

  if (signupInBenevityOnly) {
    return featureIsEnabled(COMPLEX_SIGNUP_FEATURE_FLAG, features, searchParams) && isComplexShiftSignup
      ? ComplexSignupShift(event, shift, modalData, setModalData, complexSignupData, setComplexSignupData, modalVisible, setModalVisible, registerComplexSignup)
      : RegisteredBenevityLink(shift);
  }
  return RegisterShift(loading, shift, registerSignup);
}

function determineDisplayItemsCount() {
  if (typeof window === "undefined") return 1;
  const windowWidth: number = window.innerWidth;

  // increased 300 -> 350 so when there are Did Not Attend & Track Time, shift card has enough width and doesn't push Track Time button to 2nd row 
  return Math.floor(windowWidth / 350) || 1;
}

export function useDisplayShiftsCount() {
  const [displayItemsCount, setDisplayItemsCount] = useState<number>(determineDisplayItemsCount());

  useEffect(() => {
    function handleResize() {
      const count: number = determineDisplayItemsCount();
      if (count !== displayItemsCount)
        setDisplayItemsCount(count);
    }
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [displayItemsCount]);

  return displayItemsCount;
}

export function ShiftCards(props: ShiftCardsProps) {
  const { t, i18n } = useTranslation();
  const { event, timezone, signupInBenevityOnly, shifts, shiftsPerRow, signups, selectedTimezone, doSignup, doCancel} = props
  const [modalVisible, setModalVisible] = useState(false);
  const [modalData, setModalData] = useState<ModalData>({
    loading: false,
    waiverLinkOpened: false,
    formValidated: false,
    questionAnswers: event.questions ? event.questions.map((question: Question) => {
        if (question.id && question.title && question.options && question.options.length > 0) { 
          return {
              question: question,
              options: question.options,
              answer: undefined
            }
          }
        return null
      }).filter(notNull)
    : undefined
  });

  return <div className="shiftCard">
    <Cards
    // {...collectionProps}
    cardDefinition={{
      header: (shift: Shift) => {
        return <Header variant="h3">{shift.name}</Header>
      },
      sections: [
        {
          content: (shift: Shift) => {
            const hasLimitedSpots = shift.signupsRemaining && shift.maxSignup;

            const startDate = shift.startDateTime ? new Date(shift.startDateTime) : null;
            const endDate = shift.endDateTime ? new Date(shift.endDateTime) : null;

            const DateTimeShiftDisplay = startDate && endDate ? ()=>{
              const diffTime = endDate.getTime() - startDate.getTime();

              const timeString = asTimeString(startDate, selectedTimezone, i18n.language, {removeTimeZone:true});
              const localeStartDateString = asDateString(startDate, selectedTimezone, i18n.language);

              const eventLocalTimeStringNoTz = asTimeString(startDate, timezone, i18n.language, {removeTimeZone:true});
              const eventLocalTimeString = asTimeString(startDate, timezone, i18n.language, {removeTimeZone:true});
              const localTimeIdentical = eventLocalTimeStringNoTz === timeString;

              return <div>
                <p><b>{t("eventDetails.shiftDisplay.date")}:</b> {localeStartDateString}</p>
                <p><b>{t("eventDetails.shiftDisplay.startTime2")}:</b> {timeString}
                {localTimeIdentical ? <></> : <div className="shiftLocalDateTime"><small><b>{t("eventDetails.shiftDisplay.localStartTime")}:</b> {eventLocalTimeString}</small></div>}
                </p>
                <p><b>{t("eventDetails.shiftDisplay.length")}:</b> {getShiftLengthLabel(t, diffTime)}</p>
              </div>
            } : ()=>{
              return <p><b>{t("eventDetails.shiftDisplay.date")}:</b> {t("eventDisplay.ongoingEvent")}</p>;
            }
              

            return (
              //THIS CLASS IS REQUIRED FOR INTEGRATION TESTING TO REGISTER THE EXISTANCE OF SHIFTS
              <div className="shift">
                <div className="shiftBody">
                  <DateTimeShiftDisplay/>
                  {hasLimitedSpots ? <p>{t("eventDetails.shiftDisplay.signedUp")}: {(shift.maxSignup ?? 0) - (shift.signupsRemaining ?? 0)} </p> : <></>}
                  <p><b>{t("eventDetails.shiftDisplay.spotsAvailable")}:</b> {hasLimitedSpots ? shift.signupsRemaining : t("eventDetails.shiftDisplay.unlimited")}</p>
                </div>
              </div>
            )
          }
        },
        {
          content: (shift: Shift) => {
            return (
              <div className="shiftFooter">
                <ActionLink 
                  signupInBenevityOnly={signupInBenevityOnly}
                  event={event}
                  shift={shift}
                  modalData={modalData}
                  setModalData={setModalData}
                  modalVisible={modalVisible}
                  setModalVisible={setModalVisible}
                  signup={signups.find(s=>s.shiftId === shift.shiftId)}
                  doSignup={ doSignup }
                  doCancel={ doCancel }
                />
              </div>
            )
          }
        }
      ]
    }}
    items={shifts}
    cardsPerRow={[{ cards: shiftsPerRow }]}
    />
  </div>
}

function RegisterShift(loading: boolean, shift: Shift, registerSignup: () => Promise<void>) {
  const { t } = useTranslation();

  return <Button fullWidth loading={loading} variant="primary"
    data-external-analytics-on="click"
    data-external-analytics-name="shifts_signup"
    data-external-analytics-attrs={`eventId:${shift.eventId}`}
    data-aci-analytics-name={`shifts_signup`}
    onClick={registerSignup}>
    {t("eventDetails.shiftDisplay.register")}
  </Button>;
}

function ComplexSignupShift(
  event: Event,
  shift: Shift,
  modalData: ModalData,
  setModalData: React.Dispatch<React.SetStateAction<ModalData>>,
  complexSignupData: ComplexSignupInputs,
  setComplexSignupData: React.Dispatch<React.SetStateAction<ComplexSignupInputs>>,
  modalVisible: boolean,
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  registerComplexSignup:() => Promise<void>,
) {
  const { t } = useTranslation();
  return (
    <>
      <Button
        fullWidth variant="primary"
        data-external-analytics-on="click"
        data-external-analytics-name="complex_signup_dialog"
        data-external-analytics-attrs={`eventId:${shift.eventId}`}
        data-aci-analytics-name={`complex_signup_dialog`}
        onClick={() => setModalVisible(true)}
      >
        {t("eventDetails.shiftDisplay.register")}
      </Button>
      <ComplexSignupDialog
        modalData={modalData}
        setModalData={setModalData}
        complexSignupData={complexSignupData}
        setComplexSignupData={setComplexSignupData}
        event={event}
        shift={shift}
        visible={modalVisible}
        onDismiss={() => setModalVisible(false)}
        registerComplexSignup={registerComplexSignup}
      />
    </>
  );
}

function RegisteredBenevityLink(shift: Shift) {
  const { t } = useTranslation();

  return <a className="registerLink"
    href={externalLinks.benevity.signup + `${shift.eventId}?utm_source=${externalLinks.utm.source}&utm_medium=${'EventPage'}&utm_campaign=${externalLinks.utm.campaign}`}
    data-external-analytics-on="click"
    data-external-analytics-name="shifts_signupInBenevityDetails"
    data-external-analytics-attrs={`eventId:${shift.eventId}`}
    data-aci-analytics-name={`shifts_signupInBenevityDetails`}
    target="_blank"
    rel="noreferrer">
    {t("eventDetails.shiftDisplay.signUp")}
  </a>;
}

function RegisteredShift(loading: boolean, shift: Shift, cancelSignup: () => Promise<void>) {
  const { t } = useTranslation();

  return <Button fullWidth loading={loading}
    data-external-analytics-on="click"
    data-external-analytics-name="shifts_cancelDetails"
    data-external-analytics-attrs={`eventId:${shift.eventId}`}
    data-aci-analytics-name={`shifts_cancelDetails`}
    onClick={cancelSignup}>
    {t("eventDetails.shiftDisplay.cancel")}
  </Button>;
}

function CompletedShift(loading: boolean, shift: Shift, cancelSignup: () => Promise<void>) {
  const { t } = useTranslation();
  
  return <div>
    <SpaceBetween direction="horizontal" size="s">
      <Button fullWidth loading={loading} variant="primary"
        data-external-analytics-on="click"
        data-external-analytics-name="shifts_didNotAttend"
        data-external-analytics-attrs={`eventId:${shift.eventId}`}
        data-aci-analytics-name={`shifts_didNotAttend`}
        onClick={cancelSignup}>
        {t("eventDetails.shiftDisplay.didNotAttend")}
      </Button>
      <div className="link-spacing">
        <Link
          external data-external-analytics-on="click"
          data-external-analytics-name="shifts_reportTime"
          data-external-analytics-attrs={`eventId:${shift.eventId}`}
          data-aci-analytics-name={`shifts_reportTime`}
          href={externalLinks.benevity.trackTime}>
          {t("eventDetails.shiftDisplay.trackTime")}
        </Link>
      </div>
    </SpaceBetween>
  </div>;
}
