// GOLDEN PATH CODE
// This is an example of how to call a Smithy client with next-page type pagination
// Ie. the client does not provide tokens for previous pages, and tokens cannot be logically generated by
// knowing the page number
import { useState } from "react";
import { RedVelvetApi } from "@amzn/red-velvet-api";
import { getClient } from "../utils/getClient";
import { useQuery, useQueryClient } from "@tanstack/react-query";

export type PaginatedResult = {
  page?:string,
}

// GOLDEN PATH NOTE
// Enforcing the query key to be the methodName and methodParameters for the RedVelvetApi client
// means that our queries can be strictly opinionated on how to call the client, and ensures that all
// parameters that are passed into the RedVelvetApi client are also passed into the react-query client
// so that caching works out-of-the-box with minimal need for developers to think about it
export type RedVelvetApiMethod = "getPeople"; // Add more method names here as needed
export type RedVelvetApiMethodParameters<K extends RedVelvetApiMethod> = Parameters<RedVelvetApi[K]>[0]; // This relies on the client generator being consistent

export type UsePaginatedRedVelvetQueryOptions<K extends RedVelvetApiMethod>=  {
  enabled?:boolean,
  redVelvetQueryKey:[K, RedVelvetApiMethodParameters<K>] // Forces query key to be [methodName, methodParameters]
}

// This is a hacky work-around to the fact that the Smithy client uses overloaded methods, which return a void type 
// if accessed via indexing (ie. client[methodName])
// When adding a new command to the list of paginated queries, add the name of the method to the RedVelvetApiMethod type,
// and then add the client call to this method
async function callClientByMethodName<K extends RedVelvetApiMethod>(methodName: RedVelvetApiMethod, parameters: RedVelvetApiMethodParameters<K>) {
  const client = getClient();
  switch(methodName) {
    case "getPeople":
      return await client.getPeople(parameters);
  }
}

export function usePaginatedRedVelvetQuery<K extends RedVelvetApiMethod>(options:UsePaginatedRedVelvetQueryOptions<K>) {
  // GOLDEN PATH NOTE
  // Pagination always starts at page 1, for compatibility with Cloudscape
  const [pageIndex, setPageIndex] = useState<number>(1);
  const queryClient = useQueryClient();
  const [methodName, methodParameters] = options.redVelvetQueryKey;

  async function callRedVelvetClient(pageIndex:number, prefetch=false){
    // GOLDEN PATH NOTE
    // If we are not navigating to the first page, we first have to find the page token for the proper page
    // However, since most users paginate by going to the next page, previous page, or first page,
    // we can leverage the caching functionality of react-query to quickly get page tokens for the next and previous pages
    // by recursively calling the same fetch for previous pages.
    let page:string | undefined = undefined;
    if(pageIndex !== 1) {
      const q = await queryClient.fetchQuery({
        // GOLDEN PATH NOTE
        // Here, and everywhere else where we set the queryKey, we prefix it with the API
        // that we are calling, so that multiple APIs with the same method won't cause cache collisions
        queryKey:["RedVelvetApi", ...options.redVelvetQueryKey, pageIndex - 1],
        queryFn: () => callRedVelvetClient(pageIndex - 1)
      });
      console.log("fetching prev query for page " + pageIndex);

      if(q?.page === undefined) {
        return undefined;
      }
      page = q.page;
    }


    // GOLDEN PATH NOTE
    // If we are on the first page, we simply call the query to get the first page of values, including the page token for
    // the next page
    const result: Awaited<ReturnType<typeof callClientByMethodName<K>>> = await callClientByMethodName(methodName, { ...methodParameters, page });

    // GOLDEN PATH NOTE
    // We start the query for the next page here, to help reduce load times for the user when going to the 
    // next page.  As a note, prefetch (and fetch) will de-dupe any in-flight queries, so this will not launch
    // extra queries if the user tries to visit the next page before the prefetch resolves
    // See https://tanstack.com/query/latest/docs/reference/QueryClient/#queryclientprefetchquery for more details
    // We also wrap it in a conditional that checks if the current query is already a pre-fetch, so that 
    // we do not continuously pre-fetch all pages, one after the other
    if(!prefetch) {
      queryClient.prefetchQuery({
        queryKey:["RedVelvetApi", ...options.redVelvetQueryKey, pageIndex + 1],
        queryFn:()=>callRedVelvetClient(pageIndex + 1, true)
      });
    }
    return result;
  }

  return {
    queryResult: useQuery({
      enabled:options.enabled,
      queryKey:["RedVelvetApi", ...options.redVelvetQueryKey, pageIndex],
      queryFn:() => callRedVelvetClient(pageIndex)
    }),
    pageIndex, 
    setPageIndex:(value:number)=>{
      if(value < 1) {
        return;
      }
      setPageIndex(value);
    },
    refresh:()=>{
      queryClient.resetQueries();
    }
  };
}