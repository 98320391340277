import { Event, Shift, Signup } from "@amzn/red-velvet-api";
import { ColumnLayout, Link, Spinner, TextContent } from "@amzn/awsui-components-react";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import Badge from "@amzn/awsui-components-react/polaris/badge";
import { useCollection } from '@amzn/awsui-collection-hooks';
import { Pagination } from '@amzn/awsui-components-react';
import { ManageLinks } from "../manageLinks"
import { useUser } from "../../hooks/user"
import { CustomIcon } from "../customIcon"
import Alert from "@amzn/awsui-components-react/polaris/alert";
import { filterBetterShifts } from "../../utils/filterUtils";

import { publishMetricsWithAttributes } from "../analytics";
import { publishKatalMetric } from "../katalAnalytics";
import { AlertInfo, useSingleBetterEventFromSearchParam, ComplexSignupInputs } from "../../hooks/betterEvents";
import { ShiftCards, useDisplayShiftsCount } from "./shiftDisplay";
import { useFeatures } from "../../hooks/features";

export const dateDisplayOptions: Intl.DateTimeFormatOptions = {
  month: "long",
  day: "numeric",
  year: "numeric"
};

export const timeDisplayOptions: Intl.DateTimeFormatOptions = {
  timeZoneName: "shortGeneric",
  hour: "numeric",
  minute: "numeric"
}

function Contact({ name, email, phone }: { name: string, email?: string, phone?: string }) {
  const { t } = useTranslation();

  return (<TextContent>
    <p>{name}</p>
    {email ? (<p>{t("eventDetails.contact.email")}: <a
      data-external-analytics-on="click"
      data-external-analytics-name="details_contactEmail"
      data-aci-analytics-name="details_contactEmail"
      href={`mailto:${email}`}>{email}</a></p>) :
      null}
    {phone ? (<p>{t("eventDetails.contact.phone")}: <a
      data-external-analytics-on="click"
      data-external-analytics-name="details_contactPhone"
      data-aci-analytics-name="details_contactPhone"
      href={`tel:${phone}`}>{phone}</a></p>) : null}
  </TextContent>)
}

export function GoBackLink() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const goBack = () => {
    // If the user navigated here from search, we want to return to the search they were previously using so we maintain
    // any filters they were using
    if (window.history.length > 1) navigate(-1);
    // However, if the user simply pasted a link into the browser, we don't have any filters to load and should just go
    // to the normal search
    else navigate('/');
  }

  return (
    <p className="go-back">
      <Link
        data-external-analytics-on="click"
        data-external-analytics-name="details_backToSearch"
        data-aci-analytics-name="details_backToSearch"
        onFollow={goBack}>
        {t("fullEvent.goBack")}
      </Link>
    </p>
  );
}

export function FullEventDisplay() {
  const [{ event, loading }, { cancelSignup, signupUserToShift, clearAlert }] = useSingleBetterEventFromSearchParam();
  const user = useUser();
  const { t } = useTranslation();


  if (loading) {
    return <Container
      header={
        <Header>
          <GoBackLink />
          <h1>{t("eventDetails.loadingEvent")}</h1>
        </Header>}>
      <Spinner />
    </Container>
  }

  if (!event?.event) {
    return <Container
      header={
        <Header>
          <GoBackLink />
          <h1>{event?.alert?.message}</h1>
        </Header>}>
    </Container>
  }
  return <FullEventContent event={event.event} shifts={event.shifts} signups={event.signups} alert={event.alert} userId={user.userId} clearAlert={clearAlert} doCancel={cancelSignup} doSignup={signupUserToShift} />
}

interface FullEventContentProps {
  event: Event;
  shifts: Shift[];
  signups: Signup[];
  alert?: AlertInfo;
  userId: string;
  clearAlert: () => void;
  doCancel: (signup: Signup) => Promise<void>;
  doSignup: (shft: Shift, user: string, complexSignups?: ComplexSignupInputs) => Promise<void>;
}

export function FullEventContent(props: FullEventContentProps) {
  const { event, shifts, signups, alert, userId, clearAlert, doCancel, doSignup } = props;
  const { t, i18n } = useTranslation();
  const shiftsPerPage = useDisplayShiftsCount();
  const [searchParams] = useSearchParams();
  const features = useFeatures();  
  
  const filteredShifts = filterBetterShifts(shifts, signups, searchParams, features);
  
  const {
    items,
    paginationProps
  } = useCollection(
    filteredShifts || [],
    {
      filtering: {},
      pagination: { pageSize: shiftsPerPage },
      sorting: {},
      selection: {},
    }
  );
  const selectedTimezone = searchParams.get("timezone") || Intl.DateTimeFormat().resolvedOptions().timeZone;
  const displayedLocation = event.location.locationType === "VIRTUAL" ?
    t("virtualLocation") :
    t("fullEvent.inPerson");

  const locationColor = event.location && event.location.city ? "grey" : "blue";

  const shiftsHeader = filteredShifts && filteredShifts.length > 0 ? "eventDetails.shifts" : "eventDetails.noShifts"

  const startDay = new Date(event.startTimestamp || "").toLocaleDateString(i18n.language,
    { ...dateDisplayOptions, timeZone: selectedTimezone || Intl.DateTimeFormat().resolvedOptions().timeZone });
  const endDay = new Date(event.endTimestamp || "").toLocaleDateString(i18n.language,
    { ...dateDisplayOptions, timeZone: selectedTimezone || Intl.DateTimeFormat().resolvedOptions().timeZone });

  return (
    <div className="full-event">
      <Container>
        <GoBackLink />
        <Header variant="h1">
          {event.title}
        </Header>
        <p className="full-subheader">
          {event.startTimestamp ? `${startDay} - ${endDay} • ` : null}
          {event.location && event.location.city ?
            t("eventDisplay.benevityButBetterPhysicalLocation", { city: event.location.city, state: event.location.province, country: event.location.country }) : null}
          <span className="location-badge"><Badge color={locationColor}>{displayedLocation}</Badge></span>
        </p>
        <p>{event.summary}</p>
      </Container>
      <div className="shiftSection">
        <Header variant="h2">
          {t(shiftsHeader)}
        </Header>
        <ShiftCards
          event={event}
          timezone={event.timeZone}
          signupInBenevityOnly={!event.rvSignupSupported}
          shifts={items}
          shiftsPerRow={shiftsPerPage}
          signups={signups}
          selectedTimezone={selectedTimezone || Intl.DateTimeFormat().resolvedOptions().timeZone}
          doSignup = { doSignup }
          doCancel = { doCancel }/>
        { alert ? <Alert      
          dismissible      
          statusIconAriaLabel={t("eventDisplay." + alert.type + "Title")}  
          onDismiss={() => {
            clearAlert();
          }}
          type={alert.type}>
          <b>{t("eventDisplay." + alert.type + "Title")}</b>
          <div>{alert.message}</div>
        </Alert> : null}
        <Header actions={<Pagination
          {...paginationProps}
          onChange={(event) => {
            publishMetricsWithAttributes("shiftPage", "page", event.detail.currentPageIndex.toString());
            publishKatalMetric("shiftPage", event.detail.currentPageIndex.toString());
            paginationProps.onChange(event);
          }}
          ariaLabels={{
            nextPageLabel: t("eventList.topPaginationControl.next"),
            previousPageLabel: t("eventList.topPaginationControl.previous"),
            paginationLabel: t("eventList.topPaginationControl.label")
          }} />} />
      </div>
      <Container>
        <Grid gridDefinition={[{ colspan: { default: 12, xs: 9 } }, { colspan: { default: 12, xs: 3 } }]}>
          <SpaceBetween direction="vertical" size="s">
            <Header variant="h2">{t("eventDetails.about")}</Header>
            <p dangerouslySetInnerHTML={{ __html: event.description || "" }} />
          </SpaceBetween>
          <ColumnLayout borders="horizontal" columns={1}>
            <Header variant="h2">Details</Header>
            <div className="sidebarSection">
              <h3><CustomIcon name="contact" /> {t("fullEvent.primaryContact")}</h3>
              <div className="sidebarContent">
                {event && event.contacts && event.contacts.length > 0 ? (<Contact
                  name={event.contacts[0].name || ""}
                  email={event.contacts[0].email || ""}
                  phone={event.contacts[0].phone || ""} />) : <Contact
                  name={"ACI Global Volunteering"}
                  email={"volunteering@amazon.com"}
                  phone={""} />}
              </div>
            </div>
            <div className="sidebarSection">
              <h3><CustomIcon name="location" /> {t("eventDetails.location")}</h3>
              <div className="sidebarContent">
                {event.location && event.location.city ?
                  <div>
                    <div>{event.location.name}</div>
                    <div>{event.location.street}</div>
                    <div>{event.location.city}, {event.location.province} {event.location.postal_code}</div>
                    {event.location.notes ? <div className="sidebar-option-margin">{event.location.notes}</div> : null}
                  </div> : <p>{t("virtualLocation")}</p>}
              </div>
            </div>
            {event.skills.length > 0 &&
              <div className="sidebarSection">
                <h3><CustomIcon name="skills" /> {t("eventDetails.skills")}</h3>
                <div className="sidebarContent">
                  <ul>
                    {
                      event.skills.map(skill =>
                        <li key={skill}>{skill}</li>)
                    }
                  </ul>
                </div>
              </div>
            }
            {event.waiver?.acceptance === "required" &&
              <div className="sidebarSection">
                <h3><CustomIcon name="requirements" /> {t("eventDetails.requirements")}</h3>
                <div className="sidebarContent">
                  <ul>
                    <li key="waiver">{t("eventDetails.waiverRequired")}</li>
                  </ul>
                </div>
              </div>
            }
            {userId + "@amazon.com" === event.ownerEmail ?
              <ManageLinks id={event.eventId} /> :
              null}
          </ColumnLayout>
        </Grid>
      </Container>
    </div>);
}
