import { Icon } from "@amzn/awsui-components-react";
import ButtonDropdown from "@amzn/awsui-components-react/polaris/button-dropdown";
import Button from "@amzn/awsui-components-react/polaris/button";
import Popover from "@amzn/awsui-components-react/polaris/popover";
import { useTranslation } from "react-i18next";

import externalLinks from "../data/externalLinks.json";
import { publishMetric } from "./analytics";
import { publishKatalMetric } from "./katalAnalytics";

export function ManageLinks({ id }: { id: number | string }) {
  const { t } = useTranslation();
  return (<div className="manageLinks">
    <ButtonDropdown items={[
      { id: "viewActivity",
        text: t("eventDetails.viewActivity"),  
        href: externalLinks.benevity.event + id, 
        external: true},
      { id: "editActivity",
        text: t("eventDetails.editActivity"), 
        href: externalLinks.benevity.editEvent.replace("{id}", id.toString()), 
        external: true},
      { id: "manageSignups",
        text: t("eventDetails.manageSignups"), 
        href: externalLinks.benevity.manageSignups.replace("{id}", id.toString()), 
        external: true},
      { id: "manageTimeTracking",
        text: t("eventDetails.manageTimeTracking"), 
        href: externalLinks.benevity.manageTime.replace("{id}", id.toString()), 
        external: true},
      { id: "sendBulkEmail",
        text: t("eventDetails.sendBulkEmail"), 
        href: externalLinks.benevity.bulkEmail.replace("{id}", id.toString()), 
        external: true},
      { id: "givehubTutorials",
        text: t("eventDetails.givehubTutorials"), 
        href: externalLinks.support.givehubTutorials, 
        external: true},
    ]}
    onItemFollow={({ detail }) => {
      publishMetric("manageEvent_" + detail.id + "OutlinkClicked");
      publishKatalMetric("ButtonDropdown", "manageEvent_" + detail.id + "OutlinkClicked");
    }}>
      <span className="manageGear"><Icon name="settings"/></span>
      {t("eventDetails.manageEvent")}
    </ButtonDropdown>

    <span className="manageInfo">
      <Popover
        position="top"
        size="small"
        header={t("eventDetails.manageInfoHeader")}
        content={<div>
          {t("eventDetails.manageInfo")}
          </div>}
        triggerType="custom">
        <Button iconName="status-info" variant="inline-icon" ariaLabel={t("eventDetails.info")}></Button>
      </Popover>
    </span>
  </div>);
}