import React from 'react';
import { Outlet } from 'react-router-dom';
import { TopNav } from "./components/topNav";
import PinpointMetrics from "./components/analytics";
import KatalMetrics from "./components/katalAnalytics";
import { useTranslation } from "react-i18next";
import { BottomNav } from "./components/bottomNav";
import { Container, Header, Spinner, Button } from "@amzn/awsui-components-react";
import { Auth } from "aws-amplify";
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

import './App.scss';
import { GateOnFeatureLoad, GateOnSignupLoaded, GateOnUserLoad } from './components/context';

const queryClient = new QueryClient()

export function LoadSsoButton() {
  const { t } = useTranslation();
  return <Container data-testid="loading-spinner" className="fa-3x">
    <Header>{t("auth.title")}</Header>
    <Button variant="primary" onClick={() => Auth.federatedSignIn({ customProvider: 'AmazonFederate' })}>
      {t("auth.login")}
    </Button>
  </Container>
}

function UnauthorizedUser() {
  const { t } = useTranslation();

  return (<Container data-testid="unauthorized" >
    <Header>{t("auth.failed")}</Header>
    <p>{t("auth.failedInfo")}</p>
  </Container>);
}

function Loading() {
  return <Container data-testid="loading-spinner" className="fa-3x">
    <Spinner/>
  </Container>
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <GateOnUserLoad loading={() => <Loading />} error={() => <LoadSsoButton />} unauthorized={() => <UnauthorizedUser />} authorized={() =>
        <GateOnFeatureLoad loading={() => <Loading />} render={() =>
          <GateOnSignupLoaded loading={() => <Loading />} render={() =>
            <div className='App' data-testid="app-container">
              <div className="header">
                <PinpointMetrics />
                <KatalMetrics />
                <TopNav />
              </div>
              <main>
                <Outlet />
              </main>
              <BottomNav />
            </div>} />}
        />}
      />
    </QueryClientProvider>)
}

export default App;
