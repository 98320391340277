import { RedVelvetApi, Shift, Signup, Event } from "@amzn/red-velvet-api";
import { getClient } from "../../utils/getClient";

type Status = {
  status:{ type: "success"}
} | {
  status:{ type: "error", reason:string}
}

type WithStatus<T> = {value?:T} & Status;
const asWithStatus = <T,>(promise:PromiseSettledResult<T>):WithStatus<T> => {
  return promise.status === "fulfilled" ? {
    status:{type:"success"},
    value:promise.value
  } : {
    status:{
      type:"error",
      reason:`${promise.reason}`
    }
  }
}

/**
 * Simplify getting paged responses
 */

type PagedResponse<T> = {
  items:T[],
  next?:string
}
type GetPage<T> = (page?:string)=>Promise<PagedResponse<T>>;

async function loadAllPages<T>(getPage:GetPage<T>):Promise<T[]>{
  let prev: string | undefined = undefined;
  let all:T[] = [];

  do {
    const r:PagedResponse<T> = await getPage(prev);
    prev = r.next;
    all = all.concat(r.items);
  } while (prev !== undefined)

  return all;
}

const loadAllShiftsForEvent = async (client:RedVelvetApi, eventId:string):Promise<Shift[]>=>{
  return await loadAllPages<Shift>(async (page?:string)=>{
    const ret = await client.getShiftsByEvent({eventId, page});
    return {
      items:ret.shifts,
      next:ret.page
    };
  })
};

const loadAllUsersignupsForEvent = async (client:RedVelvetApi, eventId:string, alias:string):Promise<Signup[]> =>{
  return await loadAllPages<Signup>(async (page?:string)=>{
    const ret = await client.getSignupsByQueryParam({eventId:eventId, alias, page});
    return {
      items:ret.signups || [],
      next:ret.page
    }
  })
}


export type LoadedEvent = {
  eventId:string,
  event:WithStatus<Event>
  shifts:WithStatus<Shift[]>,
  signups:WithStatus<Signup[]>
}

/**
 * batch load all the events specified by id. Requires the logged in user to filter the signups.
 */
export async function loadBetterEvents(ids: string[], userId: string):Promise<LoadedEvent[]>{
  const client = getClient();
  const events: WithStatus<Event>[] = (await Promise.allSettled(ids.map(eventId => client.getEventById({ eventId })))).map(asWithStatus);
  const shiftSets: WithStatus<Shift[]>[] = (await Promise.allSettled(ids.map(eventId => loadAllShiftsForEvent(client, eventId)))).map(asWithStatus);
  const signupSets: WithStatus<Signup[]>[] = (await Promise.allSettled(ids.map(eventId => loadAllUsersignupsForEvent(client, eventId, userId)))).map(asWithStatus);

  return ids.map((id, index)=> ({
    eventId:id,
    event:events[index],
    shifts:shiftSets[index],
    signups:signupSets[index]
  }));
}
