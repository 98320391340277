import { useContext } from "react";
import { userContext } from "../components/context";

export interface UserData {
  userId: string;
  employeeId: string;
  permissions: string[];
  isAuthenticated: boolean;
  error?: string;
}

//use the userContext to provide the user Information.
export function useUser(): UserData {
  const userInfo = useContext(userContext);

  if (userInfo.user.userId) {
    return { ...userInfo.user, isAuthenticated: true };
  }
  // Error sent from Amplify Auth.
  if (userInfo.error) {
    return { ...userInfo.user, isAuthenticated: false, error: userInfo.error };
  }

  // Default: No Authentication nor error yet. Keep waiting.
  return { ...userInfo.user, isAuthenticated: false };
}
