import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Shift, Event, Question, Option } from "@amzn/red-velvet-api";
import Button from "@amzn/awsui-components-react/polaris/button";
import Modal, { ModalProps } from "@amzn/awsui-components-react/polaris/modal";
import Select, { SelectProps } from "@amzn/awsui-components-react/polaris/select";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Checkbox from "@amzn/awsui-components-react/polaris/checkbox";
import Form from "@amzn/awsui-components-react/polaris/form";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Input from "@amzn/awsui-components-react/polaris/input";
import { useTshirtSizeComplexSignupFlag } from "../../utils/configFlag";
import { ComplexSignupInputs } from "../../hooks/betterEvents";

export interface ModalData {
  loading: boolean;
  waiverLinkOpened: boolean;
  formValidated: boolean;
  questionAnswers?: QuestionAnswerSet[];
}

interface ComplexSignupDialogProps {
  modalData: ModalData;
  setModalData: React.Dispatch<React.SetStateAction<ModalData>>;
  complexSignupData: ComplexSignupInputs;
  setComplexSignupData: React.Dispatch<React.SetStateAction<ComplexSignupInputs>>;
  event: Event;
  shift: Shift;
  visible: boolean;
  onDismiss: NonNullable<ModalProps["onDismiss"]>;
  registerComplexSignup: () => Promise<void>;
}

interface QuestionAnswerSet {
  question: Question;
  options: Option[];
  answer?: Option;
}

export function ComplexSignupDialog(props: ComplexSignupDialogProps) {
  const { t } = useTranslation();
  const {
    modalData,
    setModalData,
    complexSignupData,
    setComplexSignupData,
    event,
    shift,
    visible,
    onDismiss,
    registerComplexSignup
  } = props;

  const tshirtSizeOptions: SelectProps.Options = t("eventDetails.shiftDisplay.complexSignup.tshirtSizes", {
    returnObjects: true
  });

  const setWaiverLinkOpened = (newValue: boolean) => {
    setModalData((prev) => ({
      ...prev,
      waiverLinkOpened: newValue
    }));
  };

  const setTshirtSize = (newValue: number) => {
    setComplexSignupData((prev) => ({
      ...prev,
      tShirtSize: event.hasTShirt ? newValue : undefined
    }));
  };

  const setNumberOfGuests = (newValue: number) => {
    setComplexSignupData((prev) => ({
      ...prev,
      numberOfGuests: event.allowGuests ? newValue : undefined
    }));
  };

  const setWaiverAccepted = (newValue: boolean) => {
    setComplexSignupData((prev) => ({
      ...prev,
      waiverAccepted: event.waiver ? newValue : undefined
    }));
  };

  // Validate form before allowing registration.
  useEffect(() => {
    const setFormValidated = (newValue: boolean) => {
      setModalData((prev) => ({
        ...prev,
        formValidated: newValue
      }));
    };

    const isAllowGuestNullOrValid =
      !event.allowGuests ||
      (shift.maxSignup ? (complexSignupData.numberOfGuests ?? 0) + 1 <= shift.signupsRemaining : true);
    if (!isAllowGuestNullOrValid) {
      setFormValidated(false);
    }
    const areQuestionsNullOrValid =
      !event.questions || (modalData.questionAnswers ?? []).every((set) => set.answer !== undefined);
    if (!areQuestionsNullOrValid) {
      setFormValidated(false);
    }
    const isWaiverNullOrValid =
      !(event.waiver && event.waiver.url) ||
      event.waiver.acceptance !== "required" ||
      (complexSignupData.waiverAccepted && modalData.waiverLinkOpened);
    if (!isWaiverNullOrValid) {
      setFormValidated(false);
    }
    if (isAllowGuestNullOrValid && isWaiverNullOrValid && areQuestionsNullOrValid) {
      setFormValidated(true);
    }
  }, [
    event.allowGuests,
    event.questions,
    event.waiver,
    shift.maxSignup,
    complexSignupData.numberOfGuests,
    complexSignupData.waiverAccepted,
    modalData.waiverLinkOpened,
    modalData.questionAnswers,
    shift.signupsRemaining,
    setModalData
  ]);

  // Clean up answers if Modal is dismissed.
  useEffect(() => {
    if (!visible) {
      setWaiverLinkOpened(false);
      setTshirtSize(1);
      setWaiverAccepted(false);
      setNumberOfGuests(0);
      setModalData((prev) => ({
        ...prev,
        questionAnswers: prev.questionAnswers?.map((qa) => ({
          ...qa,
          answer: undefined
        }))
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal onDismiss={onDismiss} visible={visible} header={event.title}>
      <p>
        <b>{shift.name}</b>
      </p>
      <p>
        <b>{t("eventDetails.shiftDisplay.date")}:</b> {shift.startDateTime?.toDateString()}
      </p>
      <p>
        <b>{t("eventDetails.shiftDisplay.startTime")}:</b> {shift.startDateTime?.toTimeString()}
      </p>

      {/* Enables applying filters when pressing enter or using a screenreader */}
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Form>
          <SpaceBetween size={"m"}>
            {event.allowGuests ? (
              <FormField
                key={`allowGuestsKey.${shift.shiftId}.addGuests`}
                label={t("eventDetails.shiftDisplay.complexSignup.addGuest")}
                warningText={
                  shift.maxSignup && 1 + (complexSignupData.numberOfGuests ?? 0) > shift.signupsRemaining
                    ? t("eventDetails.shiftDisplay.complexSignup.guestsOverLimit", {
                        allowGuests: shift.signupsRemaining - 1
                      })
                    : null
                }
              >
                <Input
                  onChange={({ detail }) => {
                    const selectNumGuests = Number.parseInt(detail.value);
                    if (selectNumGuests >= 0) {
                      // unlimited signups
                      if (!shift.maxSignup) {
                        setNumberOfGuests(selectNumGuests);
                      } else {
                        if (selectNumGuests <= shift.signupsRemaining) {
                          setNumberOfGuests(selectNumGuests);
                        }
                      }
                    }
                  }}
                  value={(complexSignupData.numberOfGuests ?? 0).toString()}
                  inputMode="numeric"
                  type="number"
                />
              </FormField>
            ) : null}

            {useTshirtSizeComplexSignupFlag && event.hasTShirt ? (
              <FormField
                key={`tShirtKey.${shift.shiftId}.sizeSelection`}
                label={t("eventDetails.shiftDisplay.complexSignup.tshirt")}
              >
                <Select
                  onChange={({ detail }) =>
                    setTshirtSize(detail.selectedOption.value ? Number.parseInt(detail.selectedOption.value) : 1)
                  }
                  selectedOption={
                    tshirtSizeOptions.find((option) => option.value === complexSignupData.tShirtSize?.toString()) ??
                    null
                  }
                  options={tshirtSizeOptions}
                  placeholder={t("eventDetails.shiftDisplay.complexSignup.chooseTshirt")}
                />
              </FormField>
            ) : null}

            {event.questions
              ? modalData.questionAnswers?.map((set: QuestionAnswerSet, setIdx: number) => {
                  return (
                    <FormField key={set.question.id} label={set.question.title}>
                      <Select
                        onChange={({ detail }) => {
                          setModalData((prev) => ({
                            ...prev,
                            questionAnswers: prev.questionAnswers?.map((qa, index) =>
                              index === setIdx
                                ? {
                                    ...qa,
                                    answer: { id: detail.selectedOption.value, label: detail.selectedOption.label }
                                  }
                                : qa
                            )
                          }));
                        }}
                        selectedOption={
                          set.options.find((option) => option && set.answer && option.id === set.answer.id) ?? null
                        }
                        options={set.options.map((option) => ({
                          label: option.label,
                          value: option.id
                        }))}
                        placeholder={t("eventDetails.shiftDisplay.complexSignup.chooseOptions")}
                      />
                    </FormField>
                  );
                })
              : null}

            {event.waiver && event.waiver.url && event.waiver.acceptance !== "none" ? (
              <FormField
                key={`waiverKey.${shift.shiftId}.${event.waiver.acceptance}`}
                label={t("eventDetails.shiftDisplay.complexSignup.waiver")}
              >
                <SpaceBetween size="s">
                  <a
                    target="_blank"
                    href={event.waiver.url}
                    onContextMenu={() => setWaiverLinkOpened(true)}
                    onClick={() => setWaiverLinkOpened(true)}
                    rel="noreferrer"
                  >
                    {t("eventDetails.shiftDisplay.complexSignup.clickViewWaiver")}
                  </a>
                  <Checkbox
                    checked={complexSignupData.waiverAccepted ?? false}
                    onChange={({ detail }) => setWaiverAccepted(detail.checked)}
                  >
                    {t("eventDetails.shiftDisplay.complexSignup.acceptWaiver")}
                  </Checkbox>
                </SpaceBetween>
              </FormField>
            ) : null}
            <Button
              fullWidth
              loading={modalData.loading}
              disabled={!modalData.formValidated}
              variant="primary"
              data-external-analytics-on="click"
              data-external-analytics-name="shifts_complex_signup"
              data-external-analytics-attrs={`eventId:${shift.eventId}`}
              data-aci-analytics-name={`shifts_complex_signup`}
              onClick={registerComplexSignup}
            >
              {t("eventDetails.shiftDisplay.register")}
            </Button>
          </SpaceBetween>
        </Form>
      </form>
    </Modal>
  );
}
