import { QuestionAnswer, Shift, Signup } from "@amzn/red-velvet-api";
import { getClient } from "../../utils/getClient";
import { useTshirtSizeComplexSignupFlag } from "../../utils/configFlag";

export async function signupUsertoShift(
  user: string,
  shift: Shift,
  featureFlags: string[] = [],
  guestCount?: number,
  tShirtSizeId?: number,
  questionAnswers?: QuestionAnswer[],
  waiverAccepted?: boolean,
) {
  const client = getClient(featureFlags);
  const createSignupParams = {
    shift: shift.shiftId,
    alias: user,
    guestCount,
    questionAnswers,
    waiverAccepted
  }
  
  const result = await client.createSignup(
    tShirtSizeId && useTshirtSizeComplexSignupFlag 
      ? { ...createSignupParams, tShirtSizeId } 
      : createSignupParams
  )

  return {
    signupId:result.signupId
  }
}

export async function cancelSignup(signup:Signup) {
  await getClient().deleteSignup({signupId: signup.signupId});
  return;
}
