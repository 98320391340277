import React from "react";
import Container from "@amzn/awsui-components-react/polaris/container";
import Badge from "@amzn/awsui-components-react/polaris/badge";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import { filterBetterShifts } from "../../utils/filterUtils";
import { useTranslation } from "react-i18next";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import { ShiftCards } from "./shiftDisplay"
import { Signup as BetterSignup, Shift as BetterShift } from "@amzn/red-velvet-api";
import { ComplexSignupInputs, FullEvent } from "../../hooks/betterEvents";
import { SpaButton, SpaLink } from "../SpaLink";
import { useFeatures } from "../../hooks/features";


export const dateDisplayOptions: Intl.DateTimeFormatOptions = {
  month: "short",
  day: "numeric",
};

interface EventDisplayProps {
  event: FullEvent,
  dismissAlert: ()=>void,
  doSignup: (shift:BetterShift, alias:string, complexSignup?: ComplexSignupInputs)=>Promise<void>,
  doCancel: (signup:BetterSignup)=>Promise<void>,
  urlSearchParams: URLSearchParams
}

function getShiftTotals(shifts: BetterShift[]) {
  let totalSpots = 0;
  let totalShifts = 0;
  shifts.forEach(shift => {
    if(!shift.maxSignup ) {
      totalSpots = Infinity;
      totalShifts++;
    } else if(shift.maxSignup && shift.signupsRemaining > 0) {
      totalSpots = totalSpots === Infinity ? Infinity : totalSpots + shift.signupsRemaining;
      totalShifts++;
    }
  });

  return {
    totalSpots,
    totalShifts
  }
}

export function SearchEventCard(props: EventDisplayProps) {
  const { event, dismissAlert, doSignup, doCancel, urlSearchParams } = props;
  const { t, i18n } = useTranslation();
  const features = useFeatures()
  
  const alertContent = event.alert !== undefined ? <Alert
    dismissible
    statusIconAriaLabel={t("eventDisplay." + event.alert.type + "Title")}
    onDismiss={() => {
      dismissAlert();
    }}
    type={event.alert.type}>
    <b>{t("eventDisplay." + event.alert.type + "Title")}</b>
    <div>{event.alert.message}</div>
  </Alert> : null;

  if(event.event === undefined) {
    return <Container>
      { alertContent}
    </Container>
  }

  const eventValue = event.event;

  const filteredShifts = filterBetterShifts(event.shifts, event.signups, urlSearchParams, features);
  const { totalSpots, totalShifts } = getShiftTotals(filteredShifts);
  const hasUnlimitedSpots = totalSpots === Infinity;

  const displayedLocation = eventValue.location.city === undefined ?
    t("virtualLocation") :
    t("eventDisplay.benevityButBetterPhysicalLocation", { city: eventValue.location.city, state: eventValue.location.province, country: eventValue.location.country || "" });

  const selectedTimezone = urlSearchParams.get("timezone");
  const urlFeatures = urlSearchParams.get("features");

  // ensures the feature flag is consistent when the page is changed in any test (build time or runtime), 
  // so we don’t have feature flags changing between pages on tests 
  // (ie first page is default and then click on an event and end up in BenevityButBetter)
  const viewDetailsUrl = `/view-event?id=${eventValue.eventId}${selectedTimezone ? '&timezone=' + selectedTimezone : ''}${urlFeatures ? '&features=' + urlFeatures : ''}`;

  const startDay = eventValue.startTimestamp?.toLocaleDateString(i18n.language,
                  {...dateDisplayOptions, timeZone: selectedTimezone || Intl.DateTimeFormat().resolvedOptions().timeZone});
  const endDay = eventValue.endTimestamp?.toLocaleDateString(i18n.language,
                  {...dateDisplayOptions, timeZone: selectedTimezone || Intl.DateTimeFormat().resolvedOptions().timeZone});

  return (<Container>
    <Badge color={"grey"}>{displayedLocation}</Badge>
    <SpaLink
      data-external-analytics-on="click"
      data-external-analytics-name="searchResult_title"
      data-aci-analytics-name="searchResult_title"
      className={"card-title"}
      href={viewDetailsUrl}>
      <h2>{eventValue.title}</h2>
    </SpaLink>
    <SpaceBetween direction="vertical" size="xxxs">
      <small className="minor-text">{startDay} - {endDay} | {displayedLocation} </small>
      <small className="minor-text">{ hasUnlimitedSpots ? t("eventDetails.shiftDisplay.unlimited"): t("eventDisplay.totalSpots", { count: totalSpots })} {t("eventDisplay.totalShifts", { count: totalShifts })}</small>
      { eventValue.cause ? 
        <small>
          {t("eventDisplay.organization", { orginization: eventValue.cause })}
        </small> : null
      }
      { 
        //TODO: interests are not currently in the new model. Need to add back in : https://issues.amazon.com/issues/cce-3378
        //event.value. ? <small>{t("eventDisplay.interests")} {event.interests.map(string => <Badge color={"grey"}>{string}</Badge>)}</small> : null 
      }
      { eventValue.skills.length > 0 && 
        <small>
          <SpaceBetween direction="horizontal" size="xxs">
            <span>{t("eventDisplay.skills")}</span>
            {eventValue.skills.map(string => <Badge key={string} color={"grey"}>{string}</Badge>)}
          </SpaceBetween>
        </small> 
      }
    </SpaceBetween>
    <p className="description">
      {eventValue.summary}{eventValue.summary?.length === 180 ? "..." : null}{"\t"}
      <SpaLink
        data-external-analytics-on="click"
        data-external-analytics-name="searchResult_readMore"
        data-aci-analytics-name="searchResult_readMore"
        className="readmore-link"
        href={viewDetailsUrl}>
        {t("eventDisplay.readMore")}
      </SpaLink>
    </p>
    { filteredShifts.length !== 0 ?
    <ShiftCards 
        event={event.event}
        timezone={event.event.timeZone}
        signupInBenevityOnly={!event.event.rvSignupSupported}
        shifts={filteredShifts.slice(0,3)}
        shiftsPerRow={3}
        signups = {event.signups}
        selectedTimezone={selectedTimezone || Intl.DateTimeFormat().resolvedOptions().timeZone}
        doSignup = { doSignup }
        doCancel = { doCancel }/>
    : <></> }
    {alertContent}
    <div className="shift-options">
    <SpaButton
      data-external-analytics-on="click"
      data-external-analytics-name="searchResult_viewAllShifts"
      data-aci-analytics-name="searchResult_viewAllShifts"
      href={viewDetailsUrl}
      variant="primary">
      View All Shifts
    </SpaButton>
    </div>
  </Container>);
}